import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "deposit-record-popup"
};
const _hoisted_2 = {
  class: "popup-main"
};
const _hoisted_3 = {
  class: "popup-header"
};
const _hoisted_4 = {
  class: "popup-title"
};
const _hoisted_5 = {
  class: "popup-content"
};
const _hoisted_6 = {
  class: "record-wrap"
};
const _hoisted_7 = {
  class: "record-header"
};
const _hoisted_8 = {
  class: "record-header-item date"
};
const _hoisted_9 = {
  class: "record-header-item order-no"
};
const _hoisted_10 = {
  class: "record-header-item"
};
const _hoisted_11 = {
  class: "record-header-item"
};
const _hoisted_12 = {
  class: "scroll-view"
};
const _hoisted_13 = {
  class: "record-item date"
};
const _hoisted_14 = {
  class: "record-item order-no flex justify-end"
};
const _hoisted_15 = {
  class: "record-item amount"
};
const _hoisted_16 = {
  key: 1
};
import { ref, inject, onMounted } from 'vue';
import { useHelpTool } from '@/hooks';
import { queryDepositOrder } from '@/api';
export default {
  __name: 'DepositRecord',
  emits: ['onClose'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const {
      copy
    } = useHelpTool();
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const dataList = ref([]);
    const current = ref(1);
    const pageSize = ref(10);
    onMounted(() => {
      fetchList();
    });
    const fetchList = () => {
      queryDepositOrder({
        pageSize: pageSize.value,
        current: current.value
      }).then(res => {
        dataList.value = dataList.value.concat(res.data || []);
        if (res.total <= res.page * pageSize.value) {
          finished.value = true;
        } else {
          current.value = res.page + 1;
        }
        loading.value = false;
      });
    };
    const loadData = () => {
      if (refreshing.value) {
        dataList.value = [];
        current.value = 1;
        refreshing.value = false;
      }
      fetchList();
    };
    const refreshData = () => {
      // 清空列表数据
      finished.value = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      loadData();
    };
    const statusClass = status => {
      if (status === 3) {
        return 'error';
      } else if (status === 1) {
        return 'success';
      }
    };
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_list = _resolveComponent("van-list");
      const _component_van_empty = _resolveComponent("van-empty");
      const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");
      const _component_van_popup = _resolveComponent("van-popup");
      const _directive_audio = _resolveDirective("audio");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_popup, {
        show: true,
        "close-on-click-overlay": false,
        "lock-scroll": false
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('finance.deposit.recordTitle')), 1), _withDirectives(_createVNode(_component_van_image, {
          class: "close-icon",
          src: require('@/assets/img/common/popup-close.png'),
          onClick: _cache[0] || (_cache[0] = $event => emit('onClose'))
        }, null, 8, ["src"]), [[_directive_audio, void 0, "close"]])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('label.time')), 1), _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('label.orderNum')), 1), _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('label.amount')), 1), _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('label.status')), 1)]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_van_pull_refresh, {
          modelValue: refreshing.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => refreshing.value = $event),
          onRefresh: refreshData
        }, {
          default: _withCtx(() => [dataList.value.length > 0 ? (_openBlock(), _createBlock(_component_van_list, {
            key: 0,
            loading: loading.value,
            "onUpdate:loading": _cache[1] || (_cache[1] = $event => loading.value = $event),
            class: "record-list",
            finished: finished.value,
            "finished-text": _ctx.$t('common.noMore'),
            onLoad: loadData
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataList.value, item => {
              return _openBlock(), _createElementBlock("div", {
                class: "record-row",
                key: item.id
              }, [_createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$moment(item.createTime * 1000).format('YYYY-MM-DD HH:mm:ss')), 1), _createElementVNode("div", _hoisted_14, [_createTextVNode(_toDisplayString(item.orderNo) + " ", 1), _withDirectives(_createVNode(_component_van_image, {
                class: "copy-icon",
                src: require('@/assets/img/common/copy.png'),
                onClick: $event => _unref(copy)(item.orderNo)
              }, null, 8, ["src", "onClick"]), [[_directive_audio]])]), _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$filter.outputAmount(item.amount, 2)), 1), _createElementVNode("div", {
                class: _normalizeClass(`record-item status ${statusClass(item.status)}`)
              }, _toDisplayString(_ctx.$t(`finance.deposit.status.${item.status || 0}`)), 3)]);
            }), 128))]),
            _: 1
          }, 8, ["loading", "finished", "finished-text"])) : (_openBlock(), _createElementBlock("div", _hoisted_16, [_createVNode(_component_van_empty, {
            description: _ctx.$t('common.noData')
          }, null, 8, ["description"])]))]),
          _: 1
        }, 8, ["modelValue"])])])])])]),
        _: 1
      })]);
    };
  }
};