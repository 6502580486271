import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "security-center-popup"
};
const _hoisted_2 = {
  class: "popup-main"
};
const _hoisted_3 = {
  class: "popup-header"
};
const _hoisted_4 = {
  class: "popup-title"
};
const _hoisted_5 = {
  class: "popup-content"
};
const _hoisted_6 = {
  class: "menu-list"
};
const _hoisted_7 = ["onClick"];
const _hoisted_8 = {
  class: "menu-item-right"
};
const _hoisted_9 = {
  class: "menu-item-title"
};
const _hoisted_10 = {
  class: "menu-item-extra"
};
import { ref, onMounted, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { usePopupStore, useUserStore } from '@/store';
export default {
  __name: 'SecurityCenter',
  props: ['show'],
  emits: ['onClose'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const MyDialog = inject('myDialog');
    const {
      t
    } = useI18n();
    const userStore = useUserStore();
    const popupStore = usePopupStore();
    const menuList = ref([]);
    onMounted(() => {
      menuList.value = [{
        id: 0,
        code: 'personalInformation',
        icon: require('@/assets/img/user/security-center/menu/person-info.png'),
        onClick: () => {
          popupStore.set('showProfile');
        }
      },
      // { id: 1, code: 'cryptoWallet', icon: require('@/assets/img/user/security-center/menu/crypto-wallet.png') },
      // { id: 2, code: 'eWallet', icon: require('@/assets/img/user/security-center/menu/e-wallet.png') },
      {
        id: 4,
        code: 'loginPwd',
        icon: require('@/assets/img/user/security-center/menu/login-pwd.png'),
        onClick: () => {
          popupStore.set('showChangePassword');
        }
      }, {
        id: 6,
        code: 'transactionPwd',
        icon: require('@/assets/img/user/security-center/menu/transaction-pwd.png'),
        onClick: () => {
          popupStore.set('showTransactionPassword');
        }
      }];
    });
    const onMenuClick = item => {
      if (item.onClick) {
        emit('onClose');
        item.onClick();
      }
    };
    const doLogout = () => {
      MyDialog.confirm({
        message: t('msg.logout')
      }).then(() => {
        // on confirm
        userStore.logout();
      }).catch(() => {});
    };
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_popup = _resolveComponent("van-popup");
      const _directive_audio = _resolveDirective("audio");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_popup, {
        show: __props.show,
        position: "right",
        "close-on-click-overlay": false,
        "lock-scroll": false,
        onClickOverlay: _cache[0] || (_cache[0] = $event => emit('onClose'))
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('actionBtn.securityCenter')), 1)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuList.value, item => {
          return _withDirectives((_openBlock(), _createElementBlock("div", {
            class: "menu-item flex",
            key: item.id,
            onClick: $event => onMenuClick(item)
          }, [_createVNode(_component_van_image, {
            class: "menu-item-icon",
            fit: "contain",
            src: item.icon
          }, null, 8, ["src"]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t(`user.securityCenter.${item.code}.title`)), 1), _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t(`user.securityCenter.${item.code}.extra`)), 1)])], 8, _hoisted_7)), [[_directive_audio]]);
        }), 128))]), _withDirectives((_openBlock(), _createBlock(_component_van_button, {
          class: "logout-btn",
          onClick: doLogout
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('common.logout')), 1)]),
          _: 1
        })), [[_directive_audio]])])])]),
        _: 1
      }, 8, ["show"])]);
    };
  }
};