import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-028d00d8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "balance-wrap"
};
const _hoisted_2 = {
  class: "balance-item"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "balance-value flex justify-end"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "balance-value flex justify-between"
};
const _hoisted_7 = {
  class: "balance-text"
};
import { storeToRefs } from 'pinia';
import { usePopupStore, useAccountStore } from '@/store';
export default {
  __name: 'Balance',
  emits: ['onClose'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const popupStore = usePopupStore();
    const accountStore = useAccountStore();
    const {
      balance,
      point
    } = storeToRefs(accountStore);
    const goToDeposit = () => {
      if (!popupStore.showDeposit) {
        popupStore.set('showDeposit');
        emit('onClose');
      }
    };
    const goToExchange = () => {
      if (!popupStore.showDeposit) {
        emit('onClose');
      }
      popupStore.goToDeposit({
        type: 'exchange'
      });
    };
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
        class: "balance-icon coin-icon",
        src: require('@/assets/img/common/balance/coin.png')
      }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
        class: "balance-text",
        onClick: goToDeposit
      }, _toDisplayString(_ctx.$filter.outputAmount(_unref(balance), 2)), 1), _createVNode(_component_van_image, {
        class: "refresh-icon",
        src: require('@/assets/img/common/balance/refresh.png'),
        onClick: _cache[0] || (_cache[0] = $event => _unref(accountStore).fetchBalance())
      }, null, 8, ["src"])])]), _createElementVNode("div", {
        class: "balance-item",
        onClick: goToExchange
      }, [_createElementVNode("img", {
        class: "balance-icon diamond-icon",
        src: require('@/assets/img/common/balance/diamond.png')
      }, null, 8, _hoisted_5), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$filter.outputAmount(_unref(point), 2)), 1), _createVNode(_component_van_image, {
        class: "exchange-icon",
        src: require('@/assets/img/common/balance/exchange.png')
      }, null, 8, ["src"])])])]);
    };
  }
};