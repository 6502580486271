import { ref } from 'vue';
export default {
  name: 'Switch',
  props: {
    modelValue: false
  },
  data() {
    return {
      value: this.modelValue
    };
  },
  methods: {
    updateValue() {
      this.$emit('update:modelValue', !this.value);
      this.value = !this.value;
    }
  }
};