import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "withdraw-popup"
};
const _hoisted_2 = {
  class: "popup-main"
};
const _hoisted_3 = {
  class: "popup-header"
};
const _hoisted_4 = {
  class: "popup-content"
};
const _hoisted_5 = {
  class: "content-left-wrap"
};
const _hoisted_6 = {
  class: "record-title"
};
const _hoisted_7 = {
  class: "record-wrap"
};
const _hoisted_8 = {
  class: "record-header"
};
const _hoisted_9 = {
  class: "record-header-item date"
};
const _hoisted_10 = {
  class: "record-header-item"
};
const _hoisted_11 = {
  class: "record-header-item"
};
const _hoisted_12 = {
  class: "scroll-view"
};
const _hoisted_13 = ["onClick"];
const _hoisted_14 = {
  class: "record-item date"
};
const _hoisted_15 = {
  class: "record-item"
};
const _hoisted_16 = {
  key: 1
};
const _hoisted_17 = {
  class: "content-right-wrap"
};
const _hoisted_18 = {
  class: "input-form"
};
const _hoisted_19 = {
  class: "input-label"
};
const _hoisted_20 = {
  class: "input-row"
};
const _hoisted_21 = {
  class: "input-box"
};
const _hoisted_22 = {
  class: "input-label"
};
const _hoisted_23 = {
  class: "input-row"
};
const _hoisted_24 = {
  class: "input-box"
};
const _hoisted_25 = {
  class: "input-label"
};
const _hoisted_26 = {
  class: "input-row"
};
const _hoisted_27 = {
  class: "input-box"
};
const _hoisted_28 = {
  class: "input-label"
};
const _hoisted_29 = {
  class: "input-row"
};
const _hoisted_30 = {
  class: "input-box"
};
const _hoisted_31 = {
  class: "popup-footer"
};
const _hoisted_32 = {
  class: "rule-wrap"
};
const _hoisted_33 = {
  class: "rule-title"
};
const _hoisted_34 = {
  class: "rule-text"
};
const _hoisted_35 = {
  class: "rule-text"
};
import { ref, reactive, inject, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import md5 from 'js-md5';
import { useAccountStore } from "@/store";
import WithdrawAccount from './WithdrawAccount';
import InputPassword from '@/components/User/InputPassword';
import { queryWithdrawOrder, queryWithdrawAccount, createWithdraw } from '@/api';
export default {
  __name: 'Withdraw',
  emits: ['onClose'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const MyToast = inject('myToast');
    const {
      t
    } = useI18n();
    const accountStore = useAccountStore();
    const {
      withdrawalAmount,
      surplus
    } = storeToRefs(accountStore);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const dataList = ref([]);
    const current = ref(1);
    const pageSize = ref(10);
    const showAccountModify = ref(false);
    const showPasswordInput = ref(false);
    const form = reactive({
      account: null,
      password: '',
      amount: null
    });
    onMounted(() => {
      accountStore.fetchBalance();
      fetchOrder();
      fetchWithdrawAccount();
    });
    const fetchWithdrawAccount = () => {
      queryWithdrawAccount().then(res => {
        form.account = res.data;
      });
    };
    const fetchOrder = () => {
      queryWithdrawOrder({
        current: current.value,
        pageSize: pageSize.value
      }).then(res => {
        dataList.value = dataList.value.concat(res.data || []);
        if (res.total <= res.page * pageSize.value) {
          finished.value = true;
        } else {
          current.value = res.page + 1;
        }
        loading.value = false;
      });
    };
    const loadData = () => {
      if (refreshing.value) {
        dataList.value = [];
        current.value = 1;
        refreshing.value = false;
      }
      fetchOrder();
    };
    const refreshData = () => {
      // 清空列表数据
      finished.value = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      loadData();
    };
    const reloadData = () => {
      refreshing.value = true;
      loadData();
    };
    const setAccount = account => {
      showAccountModify.value = false;
      form.account = account;
    };
    const doWithdraw = () => {
      var _form$account;
      if (!showPasswordInput.value) {
        showPasswordInput.value = true;
        return;
      }
      createWithdraw({
        amount: form.amount,
        password: md5(form.password),
        bankCardId: (_form$account = form.account) === null || _form$account === void 0 ? void 0 : _form$account.id
      }).then(res => {
        form.password = '';
        showPasswordInput.value = false;
        accountStore.fetchBalance();
        reloadData();
        MyToast({
          type: 'success',
          message: t('notify.operateSuccess')
        });
      });
    };
    const statusClass = status => {
      if (status === 3 || status === 4 || status === 5) {
        return 'error';
      } else if (status === 2) {
        return 'success';
      }
    };
    const clickRecord = item => {
      if (item.message) {
        MyToast({
          message: `${t('label.reason')}: ${item.message}`
        });
      }
    };
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_list = _resolveComponent("van-list");
      const _component_van_empty = _resolveComponent("van-empty");
      const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_popup = _resolveComponent("van-popup");
      const _directive_audio = _resolveDirective("audio");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_popup, {
        show: true,
        "close-on-click-overlay": false,
        "lock-scroll": false
      }, {
        default: _withCtx(() => {
          var _form$account2, _form$account3;
          return [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_withDirectives(_createVNode(_component_van_image, {
            class: "close-icon",
            src: require('@/assets/img/common/popup-close.png'),
            onClick: _cache[0] || (_cache[0] = $event => emit('onClose'))
          }, null, 8, ["src"]), [[_directive_audio, void 0, "close"]])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('finance.withdraw.withrawalHistory')), 1), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('label.time')), 1), _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('label.amount')), 1), _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('label.status')), 1)]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_van_pull_refresh, {
            modelValue: refreshing.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => refreshing.value = $event),
            onRefresh: refreshData
          }, {
            default: _withCtx(() => [dataList.value.length > 0 ? (_openBlock(), _createBlock(_component_van_list, {
              key: 0,
              loading: loading.value,
              "onUpdate:loading": _cache[1] || (_cache[1] = $event => loading.value = $event),
              class: "record-list",
              finished: finished.value,
              "finished-text": _ctx.$t('common.noMore'),
              onLoad: loadData
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataList.value, item => {
                return _openBlock(), _createElementBlock("div", {
                  class: "record-row",
                  key: item.id,
                  onClick: $event => clickRecord(item)
                }, [_createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$moment(item.createTime * 1000).format('YYYY-MM-DD HH:mm:ss')), 1), _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$filter.outputAmount(item.amount, 0)), 1), _createElementVNode("div", {
                  class: _normalizeClass(`record-item status ${statusClass(item.status)}`)
                }, _toDisplayString(_ctx.$t(`finance.withdraw.status.${item.status || 0}`)), 3)], 8, _hoisted_13);
              }), 128))]),
              _: 1
            }, 8, ["loading", "finished", "finished-text"])) : (_openBlock(), _createElementBlock("div", _hoisted_16, [_createVNode(_component_van_empty, {
              description: _ctx.$t('common.noData')
            }, null, 8, ["description"])]))]),
            _: 1
          }, 8, ["modelValue"])])])]), _createElementVNode("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('finance.withdraw.amountToWithdraw')), 1), _createElementVNode("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, [_createVNode(_component_van_field, {
            modelValue: form.amount,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => form.amount = $event),
            class: "input-field",
            placeholder: _ctx.$t('placeholder.amount').format({
              min: '100',
              max: '49,999'
            }),
            onInput: _cache[4] || (_cache[4] = $event => form.amount = _ctx.$filter.number($event.target.value)),
            onBlur: _cache[5] || (_cache[5] = $event => form.amount = _ctx.$filter.number($event.target.value)),
            type: "digit"
          }, null, 8, ["modelValue", "placeholder"])])]), _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('finance.withdraw.amountCanbeWithdraw')), 1), _createElementVNode("div", _hoisted_23, [_createElementVNode("div", _hoisted_24, "₱ " + _toDisplayString(_ctx.$filter.outputAmount(_unref(withdrawalAmount), 2)), 1)]), _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t('finance.withdraw.turnover')), 1), _createElementVNode("div", _hoisted_26, [_createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.$filter.outputAmount(_unref(surplus), 2)), 1)]), _createElementVNode("div", _hoisted_28, _toDisplayString(form.account ? _ctx.$filter.upperCaseFirstLetter(form.account.bank) : _ctx.$t('label.bank')), 1), _createElementVNode("div", _hoisted_29, [_createElementVNode("div", _hoisted_30, [_createVNode(_component_van_field, {
            "model-value": (_form$account2 = form.account) === null || _form$account2 === void 0 ? void 0 : _form$account2.account,
            class: "input-field",
            readonly: ""
          }, null, 8, ["model-value"])]), _withDirectives((_openBlock(), _createBlock(_component_van_button, {
            class: "modify-btn",
            onClick: _cache[6] || (_cache[6] = $event => showAccountModify.value = true)
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('common.modify')), 1)]),
            _: 1
          })), [[_directive_audio]])])])])]), _createElementVNode("div", _hoisted_31, [_createElementVNode("div", _hoisted_32, [_createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t('common.rules')), 1), _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.$t('finance.withdraw.rule.content1')), 1), _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.$t('finance.withdraw.rule.content2')), 1)]), _withDirectives((_openBlock(), _createBlock(_component_van_button, {
            class: "withdraw-btn",
            disabled: Number(form.amount) === 0 || !((_form$account3 = form.account) !== null && _form$account3 !== void 0 && _form$account3.id),
            onClick: doWithdraw
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('common.withdraw')), 1)]),
            _: 1
          }, 8, ["disabled"])), [[_directive_audio]])])])];
        }),
        _: 1
      })]), showAccountModify.value ? (_openBlock(), _createBlock(_unref(WithdrawAccount), {
        key: 0,
        defultData: form.account,
        onOnConfirm: setAccount,
        onOnClose: _cache[7] || (_cache[7] = $event => showAccountModify.value = false)
      }, null, 8, ["defultData"])) : _createCommentVNode("", true), showPasswordInput.value ? (_openBlock(), _createBlock(_unref(InputPassword), {
        key: 1,
        modelValue: form.password,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => form.password = $event),
        onOnConfirm: doWithdraw,
        onOnClose: _cache[9] || (_cache[9] = $event => showPasswordInput.value = false)
      }, null, 8, ["modelValue"])) : _createCommentVNode("", true)], 64);
    };
  }
};