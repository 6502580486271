import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "change-password-popup"
};
const _hoisted_2 = {
  class: "popup-main"
};
const _hoisted_3 = {
  class: "popup-header"
};
const _hoisted_4 = {
  class: "popup-content"
};
const _hoisted_5 = {
  class: "input-label"
};
const _hoisted_6 = {
  class: "input-row"
};
const _hoisted_7 = {
  class: "input-box with-suffix"
};
const _hoisted_8 = {
  class: "suffix-wrap"
};
const _hoisted_9 = {
  class: "input-label"
};
const _hoisted_10 = {
  class: "input-row"
};
const _hoisted_11 = {
  class: "input-box with-suffix"
};
const _hoisted_12 = {
  class: "suffix-wrap"
};
const _hoisted_13 = {
  class: "input-label"
};
const _hoisted_14 = {
  class: "input-row"
};
const _hoisted_15 = {
  class: "input-box with-suffix"
};
const _hoisted_16 = {
  class: "suffix-wrap"
};
import { ref, reactive, computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import md5 from 'js-md5';
import { useUserStore } from '@/store';
export default {
  __name: 'ChangePassword',
  emits: ['onClose'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const MyToast = inject('myToast');
    const {
      t
    } = useI18n();
    const userStore = useUserStore();
    const {
      isNullPasswordTrade
    } = storeToRefs(userStore);
    const showPassword = ref(false);
    const form = reactive({
      password: '',
      confirmPwd: '',
      oldPassword: ''
    });
    const confirmDisabled = computed(() => {
      return form.password === '' || form.confirmPwd === '' || form.password !== form.confirmPwd;
    });
    const inputBlur = e => {
      let field = e.target.name;
      if (field) {
        form[field] = form[field].replace(/ +/g, '');
      }
    };
    const doConfirm = () => {
      userStore.updateLoginPwd({
        password: md5(form.password),
        oldPassword: md5(form.oldPassword)
      }, () => {
        MyToast({
          type: 'success',
          message: t('notify.operateSuccess')
        });
        emit('onClose');
      });
    };
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_form = _resolveComponent("van-form");
      const _component_van_popup = _resolveComponent("van-popup");
      const _directive_audio = _resolveDirective("audio");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_popup, {
        show: true,
        "close-on-click-overlay": false,
        "lock-scroll": false
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", null, _toDisplayString(_ctx.$t('user.securityCenter.loginPwd.title')), 1), _withDirectives(_createVNode(_component_van_image, {
          class: "close-icon",
          src: require('@/assets/img/common/popup-close.png'),
          onClick: _cache[0] || (_cache[0] = $event => emit('onClose'))
        }, null, 8, ["src"]), [[_directive_audio, void 0, "close"]])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_van_form, {
          class: "input-form style2",
          onSubmit: doConfirm
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('label.oldPwd')) + ":", 1), _createElementVNode("div", _hoisted_6, [_createVNode(_component_van_image, {
            class: "prefix-img",
            src: require('@/assets/img/user/password/lock.png')
          }, null, 8, ["src"]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_van_field, {
            modelValue: form.oldPassword,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => form.oldPassword = $event),
            name: "oldPassword",
            class: "input-field",
            placeholder: _ctx.$t('placeholder.pwd'),
            type: !showPassword.value ? 'password' : 'text',
            onBlur: inputBlur,
            clearable: ""
          }, null, 8, ["modelValue", "placeholder", "type"]), _createElementVNode("div", _hoisted_8, [_withDirectives(_createVNode(_component_van_icon, {
            class: "eye-icon",
            name: !showPassword.value ? 'eye-o' : 'closed-eye',
            onClick: _cache[2] || (_cache[2] = $event => showPassword.value = !showPassword.value)
          }, null, 8, ["name"]), [[_directive_audio]])])])]), _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('label.newPwd')) + ":", 1), _createElementVNode("div", _hoisted_10, [_createVNode(_component_van_image, {
            class: "prefix-img",
            src: require(`@/assets/img/user/password/${!_unref(isNullPasswordTrade) ? 'un' : ''}lock.png`)
          }, null, 8, ["src"]), _createElementVNode("div", _hoisted_11, [_createVNode(_component_van_field, {
            modelValue: form.password,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => form.password = $event),
            name: "password",
            class: "input-field",
            placeholder: _ctx.$t('placeholder.pwd'),
            type: !showPassword.value ? 'password' : 'text',
            onBlur: inputBlur,
            clearable: ""
          }, null, 8, ["modelValue", "placeholder", "type"]), _createElementVNode("div", _hoisted_12, [_withDirectives(_createVNode(_component_van_icon, {
            class: "eye-icon",
            name: !showPassword.value ? 'eye-o' : 'closed-eye',
            onClick: _cache[4] || (_cache[4] = $event => showPassword.value = !showPassword.value)
          }, null, 8, ["name"]), [[_directive_audio]])])])]), _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('label.confirmPwd')) + ":", 1), _createElementVNode("div", _hoisted_14, [_createVNode(_component_van_image, {
            class: "prefix-img",
            src: require('@/assets/img/user/password/unlock.png')
          }, null, 8, ["src"]), _createElementVNode("div", _hoisted_15, [_createVNode(_component_van_field, {
            modelValue: form.confirmPwd,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => form.confirmPwd = $event),
            name: "confirmPwd",
            class: "input-field",
            placeholder: _ctx.$t('placeholder.pwd'),
            type: !showPassword.value ? 'password' : 'text',
            onBlur: inputBlur,
            clearable: ""
          }, null, 8, ["modelValue", "placeholder", "type"]), _createElementVNode("div", _hoisted_16, [_withDirectives(_createVNode(_component_van_icon, {
            class: "eye-icon",
            name: !showPassword.value ? 'eye-o' : 'closed-eye',
            onClick: _cache[6] || (_cache[6] = $event => showPassword.value = !showPassword.value)
          }, null, 8, ["name"]), [[_directive_audio]])])])]), _withDirectives((_openBlock(), _createBlock(_component_van_button, {
            "native-type": "submit",
            class: "confirm-btn",
            disabled: confirmDisabled.value
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('common.confirm')), 1)]),
            _: 1
          }, 8, ["disabled"])), [[_directive_audio]])]),
          _: 1
        })])])]),
        _: 1
      })]);
    };
  }
};