import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "common-rewards-popup"
};
const _hoisted_2 = {
  class: "popup-main"
};
const _hoisted_3 = {
  class: "popup-content"
};
const _hoisted_4 = {
  class: "common-rewards flex"
};
const _hoisted_5 = {
  class: "reward-icon-bg"
};
const _hoisted_6 = {
  class: "reward-amount"
};
const _hoisted_7 = {
  class: "tip"
};
const _hoisted_8 = {
  class: "highlight"
};
import { ref, onMounted, onUnmounted } from 'vue';
import { useAudio } from "@/hooks";
import { useAccountStore, usePopupStore } from '@/store';
export default {
  __name: 'Reward',
  emits: ['onClose'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const {
      playAudio
    } = useAudio();
    const accountStore = useAccountStore();
    const popupStore = usePopupStore();
    const {
      rewards
    } = popupStore;
    const coldDownTime = ref(0);
    const coldDownInterval = ref(null);
    onMounted(() => {
      playAudio('open');
      accountStore.fetchBalance();
      coldDownTime.value = 5;
      coldDown();
    });
    onUnmounted(() => {
      if (coldDownInterval.value) {
        clearInterval(coldDownInterval.value);
      }
    });
    const coldDown = () => {
      if (coldDownInterval.value) {
        clearInterval(coldDownInterval.value);
      }
      coldDownInterval.value = setInterval(() => {
        coldDownTime.value = coldDownTime.value - 1;
        if (coldDownTime.value === 0) {
          emit('onClose');
        }
      }, 1000);
    };
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_popup = _resolveComponent("van-popup");
      const _directive_audio = _resolveDirective("audio");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_popup, {
        class: "full-page",
        show: true,
        "close-on-click-overlay": false,
        "lock-scroll": false
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_withDirectives(_createVNode(_component_van_image, {
          class: "close-icon",
          src: require('@/assets/img/common/popup-close.png'),
          onClick: _cache[0] || (_cache[0] = $event => emit('onClose'))
        }, null, 8, ["src"]), [[_directive_audio, void 0, "close"]]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(rewards), item => {
          return _openBlock(), _createElementBlock("div", {
            class: "reward-item",
            onClick: _cache[1] || (_cache[1] = $event => emit('onClose'))
          }, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_van_image, {
            class: "reward-icon",
            src: require(`@/assets/img/task/${item.type}.png`)
          }, null, 8, ["src"])]), _createElementVNode("div", _hoisted_6, "+" + _toDisplayString(item.amount), 1)]);
        }), 256))]), _createElementVNode("div", _hoisted_7, [_createTextVNode("Click on any item to continue. "), _createElementVNode("span", _hoisted_8, "(" + _toDisplayString(coldDownTime.value) + ")", 1)])])])]),
        _: 1
      })]);
    };
  }
};