import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "vip-rule-popup"
};
const _hoisted_2 = {
  class: "popup-main"
};
const _hoisted_3 = {
  class: "popup-header"
};
const _hoisted_4 = {
  class: "popup-title"
};
const _hoisted_5 = {
  class: "popup-content"
};
const _hoisted_6 = {
  class: "rule-text-wrap"
};
const _hoisted_7 = {
  class: "rule-title"
};
const _hoisted_8 = {
  class: "rule-sub-title"
};
const _hoisted_9 = {
  class: "rule-text"
};
const _hoisted_10 = {
  class: "rule-sub-title"
};
const _hoisted_11 = {
  class: "rule-text"
};
const _hoisted_12 = {
  class: "rule-sub-title"
};
const _hoisted_13 = {
  class: "rule-text"
};
export default {
  __name: 'Rule',
  emits: ['onClose'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_popup = _resolveComponent("van-popup");
      const _directive_audio = _resolveDirective("audio");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_popup, {
        show: true,
        "close-on-click-overlay": false,
        "lock-scroll": false
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('common.rules').toLocaleUpperCase()), 1), _withDirectives(_createVNode(_component_van_image, {
          class: "close-icon",
          src: require('@/assets/img/vip/close.png'),
          onClick: _cache[0] || (_cache[0] = $event => emit('onClose'))
        }, null, 8, ["src"]), [[_directive_audio, void 0, "close"]])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('vip.rule.title')), 1), _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('vip.rule.subTitle1')), 1), _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('vip.rule.content1')), 1), _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('vip.rule.subTitle2')), 1), _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('vip.rule.content2')), 1), _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('vip.rule.subTitle3')), 1), _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('vip.rule.content3')), 1)])])])]),
        _: 1
      })]);
    };
  }
};