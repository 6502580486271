import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "signup-bonus-page"
};
const _hoisted_2 = {
  class: "activity-list flex justify-evenly"
};
const _hoisted_3 = {
  class: "activity-box"
};
const _hoisted_4 = {
  class: "activity-title"
};
const _hoisted_5 = {
  class: "chest-wrap"
};
const _hoisted_6 = {
  class: "reward-title"
};
const _hoisted_7 = {
  class: "reward-amount"
};
const _hoisted_8 = {
  class: "reward-title"
};
const _hoisted_9 = {
  class: "reward-amount"
};
const _hoisted_10 = {
  class: "reward-title"
};
const _hoisted_11 = {
  class: "reward-amount small"
};
const _hoisted_12 = {
  class: "sale-tag"
};
const _hoisted_13 = ["onClick"];
import { ref, onMounted } from 'vue';
import { useHelpTool } from '@/hooks';
import { querySignUpActivity, joinSignUp } from '@/api';
export default {
  __name: 'SignUpBonus',
  setup(__props) {
    const {
      openBrowser
    } = useHelpTool();
    const activityList = ref([]);
    onMounted(() => {
      fetchSignUpActivity();
    });
    const fetchSignUpActivity = () => {
      querySignUpActivity().then(res => {
        const {
          activities
        } = res.data || {};
        activityList.value = activities || [];
      });
    };
    const doJoin = activityId => {
      joinSignUp({
        activityId
      }).then(res => {
        if (res.data) {
          openBrowser(res.data);
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      const _directive_audio = _resolveDirective("audio");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activityList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: _normalizeClass(`activity-item day-${item.days}`),
          key: index
        }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(item.days) + _toDisplayString(_ctx.$t('common.day')), 1), _createElementVNode("div", _hoisted_5, [_createVNode(_component_van_image, {
          class: "chest-img",
          src: require(`@/assets/img/sign-up/join/chest${item.days}.png`)
        }, null, 8, ["src"])]), _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('signUp.totalRewrad')), 1), _createElementVNode("div", _hoisted_7, "P" + _toDisplayString(item.totalReward), 1), _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('signUp.firstDepoistReward')), 1), _createElementVNode("div", _hoisted_9, "P" + _toDisplayString(item.onceReward), 1), _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('signUp.dailyRewrad')), 1), _createElementVNode("div", _hoisted_11, "P" + _toDisplayString(item.dailyReward) + "(" + _toDisplayString(item.days) + _toDisplayString(_ctx.$t('common.days')) + ")", 1), _createElementVNode("div", _hoisted_12, _toDisplayString(item.returnRate) + "%", 1)]), _withDirectives((_openBlock(), _createElementBlock("div", {
          class: "join-btn",
          onClick: $event => doJoin(item.activityId)
        }, [_createTextVNode(_toDisplayString(item.rechargeAmount) + "PHP", 1)], 8, _hoisted_13)), [[_directive_audio]])], 2);
      }), 128))])]);
    };
  }
};