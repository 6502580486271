import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "inbox-popup"
};
const _hoisted_2 = {
  class: "popup-main"
};
const _hoisted_3 = {
  class: "popup-header"
};
const _hoisted_4 = {
  class: "popup-title"
};
const _hoisted_5 = {
  class: "popup-content"
};
const _hoisted_6 = {
  class: "popup-content-left"
};
const _hoisted_7 = {
  class: "type-list"
};
const _hoisted_8 = ["onClick"];
const _hoisted_9 = {
  class: "type-item-title"
};
const _hoisted_10 = {
  class: "title-text"
};
const _hoisted_11 = {
  key: 0,
  class: "badge-wrap"
};
const _hoisted_12 = {
  class: "popup-content-right"
};
const _hoisted_13 = {
  key: 0,
  class: "scroll-view"
};
const _hoisted_14 = {
  class: "message-icon-bg"
};
const _hoisted_15 = {
  key: 0,
  class: "dot"
};
const _hoisted_16 = {
  key: 1,
  class: "message-icon-cover"
};
const _hoisted_17 = ["onClick"];
const _hoisted_18 = {
  class: "message-title"
};
const _hoisted_19 = {
  class: "message-time"
};
const _hoisted_20 = {
  class: "action-wrap flex justify-end"
};
const _hoisted_21 = {
  key: 1,
  class: "detail-wrap"
};
const _hoisted_22 = {
  class: "detail-title"
};
const _hoisted_23 = ["innerHTML"];
const _hoisted_24 = {
  class: "detail-rewards flex"
};
const _hoisted_25 = {
  class: "reward-item"
};
const _hoisted_26 = {
  class: "reward-amount"
};
const _hoisted_27 = {
  key: 0,
  class: "claimed-cover flex justify-end"
};
import { ref, computed, watch, inject, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useReddotStore, usePopupStore } from "@/store";
import { queryMessageType, queryMessage, readMessage, deleteMessage, claimMessageReward } from "@/api";
export default {
  __name: 'Inbox',
  emits: ['onClose'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const MyDialog = inject('myDialog');
    const MyToast = inject('myToast');
    const {
      t
    } = useI18n();
    const redDotStore = useReddotStore();
    const popupStore = usePopupStore();
    const typeList = ref([]);
    const activeType = ref({});
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const dataList = ref([]);
    const current = ref(1);
    const pageSize = ref(10);
    const showDetail = ref(false);
    const currentMsg = ref({});
    const deleteDisabled = computed(() => {
      return !dataList.value.some(item => item.status === 1);
    });
    watch(activeType, (newValue, oldValue) => {
      showDetail.value = false;
      // 获取消息数据
      dataList.value = [];
      current.value = 1;
      loading.value = false;
      finished.value = false;
      refreshing.value = false;
      fetchList();
    });
    onMounted(() => {
      fetchType();
    });
    const fetchType = () => {
      queryMessageType().then(res => {
        typeList.value = res.data || [];
        activeType.value = typeList.value[0];
      });
    };
    const fetchList = () => {
      queryMessage({
        pageSize: pageSize.value,
        current: current.value,
        type: activeType.value.type
      }).then(res => {
        dataList.value = dataList.value.concat(res.data || []);
        if (res.total <= res.page * pageSize.value) {
          finished.value = true;
        } else {
          current.value = res.page + 1;
        }
        loading.value = false;
      });
    };
    const loadData = () => {
      if (refreshing.value) {
        dataList.value = [];
        current.value = 1;
        refreshing.value = false;
      }
      fetchList();
    };
    const refreshData = () => {
      // 清空列表数据
      finished.value = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      loadData();
    };
    const reloadData = () => {
      refreshing.value = true;
      loadData();
    };
    const doRead = target => {
      if (target.status === 0) {
        readMessage({
          id: target.id
        }).then(res => {
          if (target.rewards && target.rewards.length > 0) {
            return false;
          }
          processRedDot(target);
        });
      }
      currentMsg.value = target;
      showDetail.value = true;
    };
    const processRedDot = target => {
      // 处理单条消息未读红点展示
      dataList.value = dataList.value.map(item => {
        if (item.id === target.id) {
          item.status = 1;
        }
        return item;
      });
      // 处理类型未读红点展示
      typeList.value = typeList.value.map(item => {
        if (item.type === target.type) {
          item.unread = item.unread - 1;
        }
        return item;
      });
      // 处理外部未读数量展示
      redDotStore.readMessage();
    };
    const doDeleteAll = () => {
      MyDialog.confirm({
        message: t('msg.deleteAllReadMessages')
      }).then(() => {
        // on confirm
        deleteMessage({
          type: activeType.value.type,
          status: 1
        }).then(res => {
          MyToast({
            type: 'success',
            message: t('notify.operateSuccess')
          });
          reloadData();
        });
      }).catch(() => {});
    };
    const doGetReward = () => {
      claimMessageReward({
        id: currentMsg.value.id
      }).then(res => {
        var _currentMsg$value;
        // MyToast({ type: 'success', message: t('notify.operateSuccess') });
        processRedDot(currentMsg.value);
        reloadData();
        currentMsg.value = {
          ...currentMsg.value,
          isClaim: true
        };
        popupStore.showRewards((_currentMsg$value = currentMsg.value) === null || _currentMsg$value === void 0 ? void 0 : _currentMsg$value.rewards);
      });
    };
    const onClose = () => {
      if (showDetail.value) {
        showDetail.value = false;
      } else {
        emit('onClose');
      }
    };
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_list = _resolveComponent("van-list");
      const _component_van_empty = _resolveComponent("van-empty");
      const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_popup = _resolveComponent("van-popup");
      const _directive_audio = _resolveDirective("audio");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_popup, {
        show: true,
        "close-on-click-overlay": false,
        "lock-scroll": false
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('inbox.title')), 1), _withDirectives(_createVNode(_component_van_image, {
          class: "close-icon",
          src: require('@/assets/img/common/popup-close.png'),
          onClick: onClose
        }, null, 8, ["src"]), [[_directive_audio, void 0, "close"]])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(typeList.value, item => {
          return _withDirectives((_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(`type-item ${item.type === activeType.value.type ? 'active' : ''}`),
            key: item.type,
            onClick: $event => activeType.value = item
          }, [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createTextVNode(_toDisplayString(item.name) + " ", 1), item.unread ? (_openBlock(), _createElementBlock("div", _hoisted_11)) : _createCommentVNode("", true)])])], 10, _hoisted_8)), [[_directive_audio]]);
        }), 128))])]), _createElementVNode("div", _hoisted_12, [!showDetail.value ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createVNode(_component_van_pull_refresh, {
          modelValue: refreshing.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => refreshing.value = $event),
          onRefresh: refreshData
        }, {
          default: _withCtx(() => [dataList.value.length > 0 ? (_openBlock(), _createBlock(_component_van_list, {
            key: 0,
            class: "message-list",
            loading: loading.value,
            "onUpdate:loading": _cache[0] || (_cache[0] = $event => loading.value = $event),
            finished: finished.value,
            "finished-text": _ctx.$t('common.noMore'),
            onLoad: loadData
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataList.value, item => {
              return _openBlock(), _createElementBlock("div", {
                class: _normalizeClass(`message-row flex ${item.status ? 'readed' : ''}`),
                key: item.id
              }, [_createElementVNode("div", _hoisted_14, [_createVNode(_component_van_image, {
                class: "message-icon",
                src: require('@/assets/img/inbox/message.png')
              }, null, 8, ["src"]), item.status === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_15)) : (_openBlock(), _createElementBlock("div", _hoisted_16))]), _withDirectives((_openBlock(), _createElementBlock("div", {
                class: "message-detail",
                onClick: $event => doRead(item)
              }, [_createElementVNode("div", _hoisted_18, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$moment(item.createTime * 1000).format('YYYY-MM-DD HH:mm:ss')), 1)], 8, _hoisted_17)), [[_directive_audio]])], 2);
            }), 128))]),
            _: 1
          }, 8, ["loading", "finished", "finished-text"])) : (_openBlock(), _createBlock(_component_van_empty, {
            key: 1,
            description: _ctx.$t('common.noData')
          }, null, 8, ["description"]))]),
          _: 1
        }, 8, ["modelValue"]), _createElementVNode("div", _hoisted_20, [_withDirectives((_openBlock(), _createBlock(_component_van_button, {
          class: "del-btn",
          disabled: deleteDisabled.value,
          onClick: doDeleteAll
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('common.deleteAll')), 1)]),
          _: 1
        }, 8, ["disabled"])), [[_directive_audio]])])])) : (_openBlock(), _createElementBlock("div", _hoisted_21, [_createElementVNode("div", _hoisted_22, _toDisplayString(currentMsg.value.title), 1), _createElementVNode("div", {
          class: "detail-content",
          innerHTML: currentMsg.value.content
        }, null, 8, _hoisted_23), currentMsg.value.rewards && currentMsg.value.rewards.length > 0 ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 0
        }, [_createElementVNode("div", _hoisted_24, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentMsg.value.rewards, reward => {
          return _openBlock(), _createElementBlock("div", _hoisted_25, [_createVNode(_component_van_image, {
            class: "reward-icon",
            src: require(`@/assets/img/task/${reward.type}.png`)
          }, null, 8, ["src"]), _createElementVNode("div", _hoisted_26, "+" + _toDisplayString(reward.amount), 1)]);
        }), 256)), currentMsg.value.isClaim ? (_openBlock(), _createElementBlock("div", _hoisted_27, [_createVNode(_component_van_image, {
          class: "claimed-img",
          src: require('@/assets/img/sign-up/signed.png')
        }, null, 8, ["src"])])) : _createCommentVNode("", true)]), !currentMsg.value.isClaim ? _withDirectives((_openBlock(), _createBlock(_component_van_button, {
          key: 0,
          class: "claim-btn",
          onClick: doGetReward
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('common.claim')), 1)]),
          _: 1
        })), [[_directive_audio]]) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true)]))])])])]),
        _: 1
      })]);
    };
  }
};