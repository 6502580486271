import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, unref as _unref, vShow as _vShow, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "profile-popup"
};
const _hoisted_2 = {
  class: "popup-main"
};
const _hoisted_3 = {
  class: "popup-header"
};
const _hoisted_4 = {
  class: "popup-title"
};
const _hoisted_5 = {
  class: "popup-content"
};
const _hoisted_6 = {
  class: "popup-content-left"
};
const _hoisted_7 = {
  class: "avatar-wrap"
};
const _hoisted_8 = {
  class: "vip-wrap"
};
const _hoisted_9 = {
  class: "popup-content-right"
};
const _hoisted_10 = {
  class: "user-info-wrap"
};
const _hoisted_11 = {
  class: "user-info-row flex"
};
const _hoisted_12 = {
  class: "user-info-label"
};
const _hoisted_13 = {
  class: "user-info-box"
};
const _hoisted_14 = {
  class: "user-info-row flex"
};
const _hoisted_15 = {
  class: "user-info-label"
};
const _hoisted_16 = {
  class: "user-info-box"
};
const _hoisted_17 = {
  class: "user-info-value"
};
const _hoisted_18 = {
  class: "user-info-row flex"
};
const _hoisted_19 = {
  class: "user-info-label"
};
const _hoisted_20 = {
  class: "user-info-box"
};
const _hoisted_21 = {
  class: "user-info-value"
};
const _hoisted_22 = {
  class: "user-info-row flex"
};
const _hoisted_23 = {
  class: "user-info-label"
};
const _hoisted_24 = {
  class: "user-info-box"
};
const _hoisted_25 = {
  class: "user-info-value"
};
const _hoisted_26 = {
  class: "divider-wrap flex"
};
const _hoisted_27 = {
  class: "divider-text"
};
const _hoisted_28 = {
  class: "scroll-wrap"
};
const _hoisted_29 = {
  class: "avatar-list"
};
const _hoisted_30 = ["onClick"];
import { ref, inject, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore, usePopupStore } from '@/store';
import { useHelpTool } from '@/hooks';
import { queryAvatar } from '@/api';
export default {
  __name: 'Profile',
  emits: ['onClose'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const {
      copy
    } = useHelpTool();
    const userStore = useUserStore();
    const popupStore = usePopupStore();
    const {
      username,
      nickname,
      phone,
      avatar,
      invitationCode,
      vip,
      registrationCode
    } = storeToRefs(userStore);
    const editNickname = ref(false);
    const _nickname = ref('');
    const nicknameRef = ref();
    const avatarList = ref([]);
    onMounted(() => {
      queryAvatar().then(res => {
        avatarList.value = res.data || [];
      });
    });
    const updateAvatar = item => {
      userStore.modifyUserInfo({
        avatar: item.avatar
      });
    };
    const doEditNickname = () => {
      if (!editNickname.value) {
        editNickname.value = true;
        _nickname.value = nickname.value;
        setTimeout(() => {
          nicknameRef.value.focus();
        }, 0);
      } else {
        if (_nickname.value) {
          editNickname.value = false;
          userStore.modifyUserInfo({
            nickname: _nickname.value
          });
        }
      }
    };
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_popup = _resolveComponent("van-popup");
      const _directive_audio = _resolveDirective("audio");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_popup, {
        show: true,
        "close-on-click-overlay": false,
        "lock-scroll": false
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('user.profile')), 1), _withDirectives(_createVNode(_component_van_image, {
          class: "close-icon",
          src: require('@/assets/img/common/popup-close.png'),
          onClick: _cache[0] || (_cache[0] = $event => emit('onClose'))
        }, null, 8, ["src"]), [[_directive_audio, void 0, "close"]])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_van_image, {
          class: "user-avatar",
          fit: "cover",
          src: _unref(avatar) || require('@/assets/img/user/avatar-default.png')
        }, null, 8, ["src"]), _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(vip)), 1)])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('label.nickname')) + ":", 1), _createElementVNode("div", _hoisted_13, [_withDirectives(_createElementVNode("div", {
          class: "user-info-value"
        }, _toDisplayString(_unref(nickname) || _unref(username)), 513), [[_vShow, !editNickname.value]]), _withDirectives(_createVNode(_component_van_field, {
          ref_key: "nicknameRef",
          ref: nicknameRef,
          class: "user-info-value",
          maxlength: "12",
          modelValue: _nickname.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _nickname.value = $event)
        }, null, 8, ["modelValue"]), [[_vShow, editNickname.value]]), !editNickname.value ? _withDirectives((_openBlock(), _createBlock(_component_van_image, {
          key: 0,
          class: "action-icon",
          fit: "contain",
          src: require('@/assets/img/user/profile/edit.png'),
          onClick: doEditNickname
        }, null, 8, ["src"])), [[_directive_audio]]) : _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 1,
          onClick: doEditNickname
        }, [_createTextVNode(_toDisplayString(_ctx.$t('common.ok').toUpperCase()), 1)])), [[_directive_audio]])])]), _createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('label.userId')) + ":", 1), _createElementVNode("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, _toDisplayString(_unref(invitationCode)), 1), _withDirectives(_createVNode(_component_van_image, {
          class: "action-icon",
          fit: "contain",
          src: require('@/assets/img/user/profile/copy.png'),
          onClick: _cache[2] || (_cache[2] = $event => _unref(copy)(_unref(invitationCode)))
        }, null, 8, ["src"]), [[_directive_audio]])])]), _createElementVNode("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('label.phone')) + ":", 1), _createElementVNode("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, _toDisplayString(_unref(phone)), 1)])]), _createElementVNode("div", _hoisted_22, [_createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t('label.superior')) + ":", 1), _createElementVNode("div", _hoisted_24, [_createElementVNode("div", _hoisted_25, _toDisplayString(_unref(registrationCode)), 1), !_unref(registrationCode) ? _withDirectives((_openBlock(), _createBlock(_component_van_image, {
          key: 0,
          class: "action-icon",
          fit: "contain",
          src: require('@/assets/img/user/profile/superior.png'),
          onClick: _cache[3] || (_cache[3] = $event => _unref(popupStore).set('showBindSuperior'))
        }, null, 8, ["src"])), [[_directive_audio]]) : _createCommentVNode("", true)])])])])]), _createElementVNode("div", _hoisted_26, [_createVNode(_component_van_image, {
          class: "divider-img",
          src: require('@/assets/img/user/profile/divider-left.png')
        }, null, 8, ["src"]), _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.$t('user.modification')), 1), _createVNode(_component_van_image, {
          class: "divider-img",
          src: require('@/assets/img/user/profile/divider-right.png')
        }, null, 8, ["src"])]), _createElementVNode("div", _hoisted_28, [_createElementVNode("div", _hoisted_29, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(avatarList.value, item => {
          return _withDirectives((_openBlock(), _createElementBlock("div", {
            class: "avatar-bg",
            onClick: $event => updateAvatar(item)
          }, [_createVNode(_component_van_image, {
            class: "avatar-img",
            fit: "cover",
            src: item.avatar
          }, null, 8, ["src"])], 8, _hoisted_30)), [[_directive_audio]]);
        }), 256))])])])]),
        _: 1
      })]);
    };
  }
};