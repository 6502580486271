import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "transaction-password-popup"
};
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import CreatePassword from './CreatePassword.vue';
import ResetPassword from './ResetPassword.vue';
import { useUserStore } from '@/store';
export default {
  __name: 'index',
  emits: ['onClose'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const userStore = useUserStore();
    const {
      isNullPasswordTrade,
      username
    } = storeToRefs(userStore);
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_unref(isNullPasswordTrade) ? (_openBlock(), _createBlock(CreatePassword, {
        key: 0,
        onOnClose: _cache[0] || (_cache[0] = $event => emit('onClose'))
      })) : (_openBlock(), _createBlock(ResetPassword, {
        key: 1,
        username: _unref(username),
        onOnClose: _cache[1] || (_cache[1] = $event => emit('onClose'))
      }, null, 8, ["username"]))]);
    };
  }
};