import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  id: "tabbar",
  class: "tabbar"
};
const _hoisted_2 = {
  class: "tabbar-wrap"
};
const _hoisted_3 = {
  class: "tabbar-center-wrap"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "tabbar-title"
};
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useHelpTool } from '@/hooks';
import { useUserStore, usePopupStore, useReddotStore } from '@/store';
import SecurityCenter from '@/components/User/SecurityCenter';
import Profile from '@/components/User/Profile';
import BindSuperior from '@/components/User/BindSuperior';
import Setting from '@/components/User/Setting';
import Deposit from '@/components/Finance/Deposit';
import Withdraw from '@/components/Finance/Withdraw';
import LiveChat from '@/components/LiveChat';
import Record from '@/components/Record';
import Event from '@/components/Event';
import Vip from '@/components/Vip/index';
import DaliyBonus from '@/components/DaliyBonus/index';
import Reward from '@/components/Common/Reward';
import LuckyWheel from '@/components/LuckyWheel/index';
import Task from '@/components/Task/index';
import Inbox from '@/components/Inbox';
import SocialMedia from '@/components/SocialMedia';
import TransactionPassword from '@/components/User/TransactionPassword/index';
import ChangePassword from '@/components/User/ChangePassword';
import Redeem from '@/components/Redeem';
import BindAccount from '@/components/User/BindAccount';
export default {
  __name: 'TabbarLayout',
  setup(__props) {
    const {
      t
    } = useI18n();
    const {
      doShare
    } = useHelpTool();
    const userStore = useUserStore();
    const {
      isNullPasswordTrade,
      loginInit
    } = storeToRefs(userStore);
    const redDotStore = useReddotStore();
    const {
      loginBonusRewardNum,
      inviteFriendToEarnNum
    } = storeToRefs(redDotStore);
    const popupStore = usePopupStore();
    const {
      showProfile,
      showBindSuperior,
      showDeposit,
      showWithdraw,
      showLiveChat,
      showRecord,
      showEvent,
      showVip,
      showSetting,
      showDaliyBonus,
      showLuckyWheel,
      showTask,
      showInbox,
      showSocialMedia,
      showReward,
      showSecurityCenter,
      showTransactionPassword,
      showChangePassword,
      showRedeem,
      showBindAccount
    } = storeToRefs(popupStore);
    const tablist = ref([]);
    onMounted(() => {
      tablist.value = [{
        tab: 0,
        title: t('tabbar.share'),
        icon: require('@/assets/img/tabbar/share.png'),
        onClick: doShare
      }, {
        tab: 1,
        title: t('tabbar.livechat'),
        icon: require('@/assets/img/tabbar/livechat.png'),
        onClick: () => {
          popupStore.set('showLiveChat');
        }
      }, {
        tab: 2,
        title: t('tabbar.record'),
        icon: require('@/assets/img/tabbar/record.png'),
        onClick: () => {
          popupStore.set('showRecord');
        }
      }, {
        tab: 3,
        title: t('tabbar.home'),
        icon: require('@/assets/img/tabbar/home.png'),
        extraClass: 'is-home'
      }, {
        tab: 4,
        title: t('tabbar.event'),
        icon: require('@/assets/img/tabbar/event.png'),
        onClick: () => {
          popupStore.set('showEvent');
        }
      }, {
        tab: 5,
        title: t('tabbar.vip'),
        icon: require('@/assets/img/tabbar/vip.png'),
        onClick: () => {
          popupStore.set('showVip');
        }
      }, {
        tab: 6,
        title: t('tabbar.setting'),
        icon: require('@/assets/img/tabbar/setting.png'),
        onClick: () => {
          popupStore.set('showSetting');
        }
      }];

      // 获取红点数量
      redDotStore.fetchData(() => {
        if (loginInit.value) {
          // if (inviteFriendToEarnNum.value) {
          //   popupStore.set('showLuckyWheel');
          // }
          if (loginBonusRewardNum.value) {
            popupStore.set('showDaliyBonus');
          }
          userStore.setLoginInit(false);
        }
      });
    });
    const onTabClick = tab => {
      if (tab.onClick) {
        tab.onClick();
      }
    };
    const clickWithdraw = () => {
      if (isNullPasswordTrade.value) {
        popupStore.set('showTransactionPassword');
      } else {
        popupStore.set('showWithdraw');
      }
    };
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_van_image = _resolveComponent("van-image");
      const _directive_audio = _resolveDirective("audio");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view, {
        class: "main-page",
        ref: "subView"
      }, null, 512), _createElementVNode("div", _hoisted_2, [_withDirectives((_openBlock(), _createElementBlock("div", {
        class: "tabbar-left-wrap",
        onClick: clickWithdraw
      }, [_createVNode(_component_van_image, {
        class: "withdraw-img",
        src: require('@/assets/img/tabbar/withdraw-img.png')
      }, null, 8, ["src"]), _createVNode(_component_van_image, {
        class: "withdraw-text",
        src: require('@/assets/img/tabbar/withdraw-text.png')
      }, null, 8, ["src"])])), [[_directive_audio]]), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tablist.value, (tab, index) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          class: "tabbar-item",
          key: tab.tab,
          onClick: $event => onTabClick(tab)
        }, [_createElementVNode("img", {
          class: _normalizeClass(`tabbar-icon ${tab.extraClass || ''}`),
          src: tab.icon
        }, null, 10, _hoisted_5), _createElementVNode("div", _hoisted_6, _toDisplayString(tab.title.toUpperCase()), 1)], 8, _hoisted_4)), [[_directive_audio]]);
      }), 128))]), _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "tabbar-right-wrap",
        onClick: _cache[0] || (_cache[0] = $event => _unref(popupStore).set('showDeposit'))
      }, [_createVNode(_component_van_image, {
        class: "deposit-img",
        src: require('@/assets/gif/deposit.gif')
      }, null, 8, ["src"]), _createVNode(_component_van_image, {
        class: "deposit-text",
        src: require('@/assets/img/tabbar/deposit-text.png')
      }, null, 8, ["src"])])), [[_directive_audio]])]), _createVNode(_unref(SecurityCenter), {
        show: _unref(showSecurityCenter),
        onOnClose: _cache[1] || (_cache[1] = $event => _unref(popupStore).set('showSecurityCenter', false))
      }, null, 8, ["show"]), _unref(showProfile) ? (_openBlock(), _createBlock(_unref(Profile), {
        key: 0,
        onOnClose: _cache[2] || (_cache[2] = $event => _unref(popupStore).set('showProfile', false))
      })) : _createCommentVNode("", true), _unref(showBindSuperior) ? (_openBlock(), _createBlock(_unref(BindSuperior), {
        key: 1,
        onOnClose: _cache[3] || (_cache[3] = $event => _unref(popupStore).set('showBindSuperior', false))
      })) : _createCommentVNode("", true), _unref(showSetting) ? (_openBlock(), _createBlock(_unref(Setting), {
        key: 2,
        onOnClose: _cache[4] || (_cache[4] = $event => _unref(popupStore).set('showSetting', false))
      })) : _createCommentVNode("", true), _unref(showDeposit) ? (_openBlock(), _createBlock(_unref(Deposit), {
        key: 3,
        onOnClose: _cache[5] || (_cache[5] = $event => _unref(popupStore).set('showDeposit', false))
      })) : _createCommentVNode("", true), _unref(showWithdraw) ? (_openBlock(), _createBlock(_unref(Withdraw), {
        key: 4,
        onOnClose: _cache[6] || (_cache[6] = $event => _unref(popupStore).set('showWithdraw', false))
      })) : _createCommentVNode("", true), _unref(showLiveChat) ? (_openBlock(), _createBlock(_unref(LiveChat), {
        key: 5,
        onOnClose: _cache[7] || (_cache[7] = $event => _unref(popupStore).set('showLiveChat', false))
      })) : _createCommentVNode("", true), _unref(showRecord) ? (_openBlock(), _createBlock(_unref(Record), {
        key: 6,
        onOnClose: _cache[8] || (_cache[8] = $event => _unref(popupStore).set('showRecord', false))
      })) : _createCommentVNode("", true), _unref(showEvent) ? (_openBlock(), _createBlock(_unref(Event), {
        key: 7,
        onOnClose: _cache[9] || (_cache[9] = $event => _unref(popupStore).set('showEvent', false))
      })) : _createCommentVNode("", true), _unref(showVip) ? (_openBlock(), _createBlock(_unref(Vip), {
        key: 8,
        onOnClose: _cache[10] || (_cache[10] = $event => _unref(popupStore).set('showVip', false))
      })) : _createCommentVNode("", true), _unref(showDaliyBonus) ? (_openBlock(), _createBlock(_unref(DaliyBonus), {
        key: 9,
        onOnClose: _cache[11] || (_cache[11] = $event => _unref(popupStore).set('showDaliyBonus', false))
      })) : _createCommentVNode("", true), _unref(showLuckyWheel) ? (_openBlock(), _createBlock(_unref(LuckyWheel), {
        key: 10,
        onOnClose: _cache[12] || (_cache[12] = $event => _unref(popupStore).set('showLuckyWheel', false))
      })) : _createCommentVNode("", true), _unref(showTask) ? (_openBlock(), _createBlock(_unref(Task), {
        key: 11,
        onOnClose: _cache[13] || (_cache[13] = $event => _unref(popupStore).set('showTask', false))
      })) : _createCommentVNode("", true), _unref(showInbox) ? (_openBlock(), _createBlock(_unref(Inbox), {
        key: 12,
        onOnClose: _cache[14] || (_cache[14] = $event => _unref(popupStore).set('showInbox', false))
      })) : _createCommentVNode("", true), _unref(showSocialMedia) ? (_openBlock(), _createBlock(_unref(SocialMedia), {
        key: 13,
        onOnClose: _cache[15] || (_cache[15] = $event => _unref(popupStore).set('showSocialMedia', false))
      })) : _createCommentVNode("", true), _unref(showRedeem) ? (_openBlock(), _createBlock(_unref(Redeem), {
        key: 14,
        onOnClose: _cache[16] || (_cache[16] = $event => _unref(popupStore).set('showRedeem', false))
      })) : _createCommentVNode("", true), _unref(showTransactionPassword) ? (_openBlock(), _createBlock(_unref(TransactionPassword), {
        key: 15,
        onOnClose: _cache[17] || (_cache[17] = $event => _unref(popupStore).set('showTransactionPassword', false))
      })) : _createCommentVNode("", true), _unref(showChangePassword) ? (_openBlock(), _createBlock(_unref(ChangePassword), {
        key: 16,
        onOnClose: _cache[18] || (_cache[18] = $event => _unref(popupStore).set('showChangePassword', false))
      })) : _createCommentVNode("", true), _unref(showReward) ? (_openBlock(), _createBlock(_unref(Reward), {
        key: 17,
        onOnClose: _cache[19] || (_cache[19] = $event => _unref(popupStore).set('showReward', false))
      })) : _createCommentVNode("", true), _unref(showBindAccount) ? (_openBlock(), _createBlock(_unref(BindAccount), {
        key: 18,
        onOnClose: _cache[20] || (_cache[20] = $event => _unref(popupStore).set('showBindAccount', false))
      })) : _createCommentVNode("", true)]);
    };
  }
};