import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-464b3d06"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "coupon-item-info"
};
const _hoisted_2 = {
  class: "coupon-title"
};
const _hoisted_3 = {
  key: 0,
  class: "coupon-time"
};
const _hoisted_4 = {
  key: 1,
  class: "coupon-time"
};
const _hoisted_5 = {
  class: "coupon-discount"
};
import { ref, computed, onMounted, onUnmounted } from 'vue';
export default {
  __name: 'Coupon',
  props: ['coupon', 'isActive'],
  emits: ['onClick', 'onTimeUp'],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;
    const coldDownTime = ref(0);
    const coldDownInterval = ref(null);
    const expireObj = computed(() => {
      let expireTime = coldDownTime.value;
      let day = Math.floor(expireTime / (60 * 60 * 24));
      let hour = Math.floor(expireTime % (60 * 60 * 24) / (60 * 60));
      let minute = Math.floor(expireTime % (60 * 60) / 60);
      let second = Math.floor(expireTime % 60);
      return {
        hour: fillZero(day * 24 + hour),
        minute: fillZero(minute),
        second: fillZero(second)
      };
    });
    onMounted(() => {
      let now = props.coupon.currentTime;
      if (now >= props.coupon.validPeriodStart && now < props.coupon.validPeriodEnd) {
        coldDownTime.value = props.coupon.validPeriodEnd - now;
        coldDown();
      }
    });
    onUnmounted(() => {
      if (coldDownInterval.value) {
        clearInterval(coldDownInterval.value);
      }
    });
    const onCouponClick = () => {
      if (coldDownTime.value) {
        emit('onClick');
      }
    };
    const coldDown = () => {
      if (coldDownInterval.value) {
        clearInterval(coldDownInterval.value);
      }
      coldDownInterval.value = setInterval(() => {
        coldDownTime.value = coldDownTime.value - 1;
        if (coldDownTime.value === 0) {
          emit('onTimeUp');
        }
      }, 1000);
    };
    const fillZero = num => {
      return num < 10 ? '0' + num : num;
    };
    return (_ctx, _cache) => {
      const _directive_audio = _resolveDirective("audio");
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["coupon-item flex", {
          disabled: !coldDownTime.value,
          'active': __props.isActive
        }]),
        onClick: onCouponClick
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('label.coupon')), 1), coldDownTime.value ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(`${expireObj.value.hour}:${expireObj.value.minute}:${expireObj.value.second}`), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$moment(__props.coupon.validPeriodStart * 1000).format('MM-DD HH:mm')), 1))]), _createElementVNode("div", _hoisted_5, _toDisplayString(__props.coupon.amount) + "%", 1)], 2)), [[_directive_audio]]);
    };
  }
};