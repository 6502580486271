import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "bind-superior-popup"
};
const _hoisted_2 = {
  class: "popup-main"
};
const _hoisted_3 = {
  class: "popup-header"
};
const _hoisted_4 = {
  class: "popup-title"
};
const _hoisted_5 = {
  class: "popup-content"
};
const _hoisted_6 = {
  class: "bind-tip"
};
import { ref, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/store';
import { bindCode } from '@/api';
export default {
  __name: 'BindSuperior',
  emits: ['onClose'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const MyToast = inject('myToast');
    const {
      t
    } = useI18n();
    const userStore = useUserStore();
    const referralCode = ref('');
    const themeVars = {
      fieldPlaceholderTextColor: 'rgba(137, 93, 52, 0.5)'
    };
    const doBind = () => {
      bindCode({
        code: referralCode.value
      }).then(res => {
        MyToast({
          type: 'success',
          message: t('notify.operateSuccess')
        });
        emit('onClose');
        userStore.loadUserInfo();
      });
    };
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_popup = _resolveComponent("van-popup");
      const _component_van_config_provider = _resolveComponent("van-config-provider");
      const _directive_audio = _resolveDirective("audio");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_config_provider, {
        "theme-vars": themeVars
      }, {
        default: _withCtx(() => [_createVNode(_component_van_popup, {
          show: true,
          "close-on-click-overlay": false,
          "lock-scroll": false
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('user.bindSuperior')), 1), _withDirectives(_createVNode(_component_van_image, {
            class: "close-icon",
            src: require('@/assets/img/common/popup-close.png'),
            onClick: _cache[0] || (_cache[0] = $event => emit('onClose'))
          }, null, 8, ["src"]), [[_directive_audio, void 0, "close"]])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_van_field, {
            modelValue: referralCode.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => referralCode.value = $event),
            class: "bind-input",
            placeholder: _ctx.$t('placeholder.referralCode')
          }, null, 8, ["modelValue", "placeholder"]), _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('user.bindTip')), 1), _withDirectives((_openBlock(), _createBlock(_component_van_button, {
            class: "bind-btn",
            disabled: !referralCode.value,
            onClick: doBind
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('common.confirm')), 1)]),
            _: 1
          }, 8, ["disabled"])), [[_directive_audio]])])])]),
          _: 1
        })]),
        _: 1
      })]);
    };
  }
};