import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "redeem-popup"
};
const _hoisted_2 = {
  class: "popup-main"
};
const _hoisted_3 = {
  class: "popup-header"
};
const _hoisted_4 = {
  class: "popup-title"
};
const _hoisted_5 = {
  class: "popup-content"
};
import { ref, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { usePopupStore } from '@/store';
import { useRedeem } from '@/api';
export default {
  __name: 'Redeem',
  emits: ['onClose'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const MyToast = inject('myToast');
    const {
      t
    } = useI18n();
    const popupStore = usePopupStore();
    const code = ref('');
    const doRedeem = () => {
      useRedeem({
        code: code.value
      }).then(res => {
        code.value = '';
        popupStore.showRewards(res.data || []);
        // emit('onClose');
      });
    };
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_popup = _resolveComponent("van-popup");
      const _directive_audio = _resolveDirective("audio");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_popup, {
        show: true,
        "close-on-click-overlay": false,
        "lock-scroll": false
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('actionBtn.redeem')), 1), _withDirectives(_createVNode(_component_van_image, {
          class: "close-icon",
          src: require('@/assets/img/common/popup-close.png'),
          onClick: _cache[0] || (_cache[0] = $event => emit('onClose'))
        }, null, 8, ["src"]), [[_directive_audio, void 0, "close"]])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_van_field, {
          modelValue: code.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => code.value = $event),
          class: "redeem-input"
        }, null, 8, ["modelValue"]), _withDirectives((_openBlock(), _createBlock(_component_van_button, {
          class: "confirm-btn",
          disabled: !code.value,
          onClick: doRedeem
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('common.confirm')), 1)]),
          _: 1
        }, 8, ["disabled"])), [[_directive_audio]])])])]),
        _: 1
      })]);
    };
  }
};